import { graphql } from "gatsby";
import React from "react";
import { Grid, Box, Text } from "theme-ui";
import Layout from "../../components/layout";
import ColumnContainer from "../../components/layout/column-container";
import ThemeableHeader from "../../components/layout/themeable-header";
import Seo from "../../components/seo";
import { keyframes } from "@emotion/react";
import { fadeInRight, fadeInLeft } from "react-animations";
import { useInView } from "react-hook-inview";

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const Event = ({ date, headline, description, even }) => {
  const [ref, isVisible] = useInView({
    threshold: 1,
  });
  return (
    <Box
      ref={ref}
      sx={{
        boxSizing: "border-box",
        width: "50%",
        left: even ? "50%" : 0,
        position: "relative",
        pl: even ? [4, 5] : null,
        pr: even ? null : [4, 5],
        mb: [-4, 5],
        // Timeline Circle
        "&::before": {
          content: '" "',
          width: 16,
          height: 16,
          bg: "white",
          border: "1px solid",
          borderColor: "lightGray",
          position: "absolute",
          borderRadius: "100%",
          top: "calc(50% - 5px)",
          left: even ? "-11.5px" : null,
          right: even ? null : "-6.5px",
          zIndex: 100,
        },
        "&::after": {
          content: '" "',
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.6s ease-in",
          width: 8,
          height: 8,
          bg: "coral",
          border: "1px solid",
          borderColor: "coral",
          position: "absolute",
          borderRadius: "100%",
          top: "calc(50% - 1px)",
          left: even ? "-7.5px" : null,
          right: even ? null : "-2.5px",
          zIndex: 100,
        },
      }}
    >
      <Grid
        gap={1}
        sx={{
          animation: `ease 0.6s ${
            even ? fadeInRightAnimation : fadeInLeftAnimation
          }`,
          animationPlayState: isVisible ? "running" : "paused",
          bg: "offWhite",
          p: [3, 4],
          borderRadius: [15, 30],
          // Timeline horizantal Arrow
          "&::before": {
            display: "inline-block",
            content: "' '",
            position: "absolute",
            top: "calc(50% - 1px)",
            left: even ? [16, 40] : null,
            right: even ? null : [16, 40],
            zIndex: 1,
            width: 0,
            height: 0,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderLeft: even ? null : "10px solid",
            borderRight: even ? "10px solid" : null,
            borderLeftColor: even ? null : "offWhite",
            borderRightColor: even ? "offWhite" : null,
          },
        }}
      >
        <Text as="h3" variant="title" sx={{ my: [0, 0, 0, 0] }}>
          {date}
        </Text>
        <Text as="h4" variant="subtitle" sx={{ fontWeight: "bold" }}>
          {headline}
        </Text>
        <Text
          as="span"
          variant="paragraph"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Grid>
    </Box>
  );
};

const Timeline = ({ data: { timeline } }) => {
  return (
    <Layout>
      <Seo title={timeline.title} />
      <ThemeableHeader
        title={timeline.title}
        pageIntroduction={timeline.fields.introduction}
        active="/"
        color="coral"
        backgroundImage={timeline?.featuredImage?.node.mediaItemUrl || false}
      />
      <Grid as="section" columns={1} gap={[4, 5]} sx={{ mb: [5, 6] }}>
        <ColumnContainer>
          <Box
            sx={{
              pt: [5, 6],
              // Timeline vertical bar
              "&::after": {
                content: '" "',
                position: "absolute",
                width: 1,
                bg: "lightGray",
                top: 0,
                bottom: 0,
                left: "50%",
                marginLeft: -1,
                borderRadius: 30,
                pt: [5, 6],
              },
            }}
          >
            {timeline.fields.events.map((event, index) => (
              <Event key={`event-${index}`} {...event} even={index % 2 !== 0} />
            ))}
          </Box>
          <Box
            sx={{
              width: 15,
              left: "calc(50% - 10px)",
              bg: "lightGray",
              height: 1,
              position: "absolute",
              bottom: 0,
            }}
          />
        </ColumnContainer>
        {/* <ColumnContainer>
        <pre>{JSON.stringify(timeline, null, 2)}</pre>
      </ColumnContainer> */}
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query TimelineQuery($slug: String!) {
    timeline: wpTimeline(slug: { eq: $slug }) {
      slug
      title
      fields: timelineCustomFields {
        introduction
        events {
          date: eventDate
          headline: eventHeadline
          description: eventDescription
        }
      }
    }
  }
`;

export default Timeline;
